
import {
  getAllChats,
  getAllFavChats,
  makeDatabase,
  sendMessageToBot,
  deleteFavoriteChat,
  makeChatFavorite,
} from "./api";
import { Menu, Drawer,message } from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  KeyboardEvent,
  useContext,
} from "react";
import useScreenType from "react-screentype-hook";
import ChatContent from "./chatContent";
import { MedboxContext } from "../../App";
import { validateInput } from "../../components/utils/validation";

interface Chat {
  chat: string;
  type: "bot" | "user";
  references?: string[];
  starred?: boolean;
}

interface BotProps {
  sidePanel: boolean;
 
}

interface Chating {
  question: string;
  answer: string;
  created_at: string;
  starred: boolean;
}
// interface Category {
//   [category: string]: Chating[];
// }

interface ChatGroup {
  [id: string]: Chating[];
}
interface Chats {
  [date: string]: ChatGroup;
}

interface CategorizedChat {
  id: string;
  chat: Chating[];
}

export const Bot: React.FC<BotProps> = ({ sidePanel}) => {
  const screenType = useScreenType();
  const [chatInput, setChatInput] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [newChat, setNewChat] = useState(true);
  const [rows, setRows] = useState<number>(1);
  const [category, setCategory] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [chatStarted, setChatStarted] = useState<boolean>(true);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [data, setData] = useState<Chats | null>(null);
  const [session, setSession] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const [unlike, setUnlike] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [allFavourites, setAllFavourites] = useState([]);
  const [favorites, setFavorites] = useState<number[]>([]);



  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };
  const formatDateTime = (dateTime: string) =>
    new Date(dateTime).toISOString().slice(0, 16).replace("T", " ");

  useEffect(() => {
    getAllChats()
      .then((response) => {
        console.log(response.data.chats)
        setData(response.data.chats);
      })
      .catch((err) => {});

    getAllFavChats()
      .then((response) => {
        setAllFavourites(response.data);
      })
      .catch((err) => {});
  }, [chatStarted, newChat,unlike]);

  // const handleStart = () => {
  //   const res=validateInput(question)
  //   if(res){
  //    message.error("Invalid input, please enter valid data")
  //    return
  //   }
  //   setLoading(true);
  //   makeDatabase(question, category)
  //     .then((res) => {
  //       setSession(res?.data?.session_id);
  //       sendMessageToBot(res?.data?.session_id, category, question)
  //         .then((res) => {
  //           const data: any = res.data;
  //           if (data) {
  //             setChatStarted(true);
  //             setLoading(false);
  //             setNewChat(false);
  //             setSelected(0)
  //             setIsVisible(false)
  //             setFavorites([]);

            
  //             const { question, answer, references, starred } = data;
  //             setChats([
  //               { chat: question, type: "user", starred: starred },
  //               {
  //                 chat: answer,
  //                 type: "bot",
  //                 references: references,
  //                 starred: starred,
  //               },
  //             ]);
  //           }
  //         })
  //         .catch((error) => {
  //           if (error?.code === "ERR_NETWORK") {
  //             message.error("Please try again later");
  //           }
  //           if (error?.response?.status == 402) {
  //             message.error("Your free search trial expired! please upgrade your plan");
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       if (err?.response?.status === 400)
  //         message.error("The papers are empty! try again");
        
  //       if (err?.response?.status === 409) 
  //         message.error("Try again later");
  //       if (err?.response?.status === 500)
  //         message.error("Server error try again");
  //       if (err?.code === "ERR_NETWORK" || err?.code === "ERR_FAILED") 
  //         message.error("Please try again later");
        
  //       if (err?.response?.status == 402) 
  //         message.error("Your free search trial expired! please upgrade your plan");
        
  //       if (err?.response?.status === 401)
  //         message.error("You are not authorized! please login");
  //     });
  // };

  const toggleFavorite = (index: number, chat: any) => {
    if (chat.starred) {
      setFavorites(favorites.filter((favIndex) => favIndex !== index));
      chat.starred=false;
      deleteFavoriteChat(session, category, chat?.chat)
        .then(() => {
          chat.starred=false;
        })
        .catch((error) => {});
    } else {
      setFavorites([...favorites, index]);
      chat.starred=true;
      makeChatFavorite(session, category, chat?.chat).then(()=>{
        setUnlike(false);
        chat.starred=true;
      }).catch((error) => {});
    }
  };

  const toggleVisibility = (index:number) => {
   setSelected(index)
   setIsVisible(!isVisible)
  };

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };

  const isButtonVisible = category && question.trim().length > 0;

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChatSubmit = (e: FormEvent) => {
    const res=validateInput(chatInput)
  
    if(res){
     message.error("Invalid input, please enter valid data")
     return
    }

    e.preventDefault();
    if (chatLoading) {
      return;
    }

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
    if (chatInput.trim() !== "") {
      setChats((prevChats) => [
        ...prevChats,
        {
          chat: chatInput,
          type: "user",
        },
      ]);
      setChatLoading(true);
      sendMessageToBot(session,chatInput)
        .then(res => {
          const data: any = res.data;
          if (data) {
            const { answer, references, starred,session_id} = data;
            setSession(session_id);
            setChats((prevChats) => [
              ...prevChats,
              {
                chat: answer,
                type: "bot",
                references: references,
                starred: starred,
              },
            ]);
          }
        })
        .catch((error:any) => {
          console.log(error)
          if (error?.code === "ERR_NETWORK") {
            message.error("Please try again later");
          }
          if (error?.response?.status == 402) {
            message.error("Your free search trial expired! please upgrade your plan");
          }
        })
        .finally(() => {
          setChatLoading(false);
        });
    }

    setChatInput("");
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setChatInput(e.target.value);
    adjustHeight();
  };

  const handleFavourite = (e: any) => {
    setNewChat(false);
    setChatStarted(false);
    setSelected(0);
    setIsVisible(false);

  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (newline in textarea)
      handleChatSubmit(e as unknown as FormEvent);
    }
  };

  const handleNewChat =()=>{
    setChatStarted(true);
    setSession("")
    setLoading(false);
    setNewChat(false);
    setSelected(0)
    setIsVisible(false)
    setChats([])
    setFavorites([]);
  }

  const adjustHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
    }
  };

  const categorizedData = data
  ? Object.entries(data).reduce<Record<string, CategorizedChat[]>>(
      (acc, [date, chatGroups]) => {
        if (!acc[date]) acc[date] = [];
        const sessionMap: Record<string, CategorizedChat> = {};
        Object.entries(chatGroups).forEach(([id, chatArray]) => {
          if (!sessionMap[id]) {
            sessionMap[id] = { id, chat: [] };
          }

          sessionMap[id].chat = sessionMap[id].chat.concat(chatArray);
        });
        Object.values(sessionMap).forEach((categorizedChat) => {
          acc[date].push(categorizedChat);
        });

        return acc;
      },
      {}
    )
  : {};


  const handleChatClick = (id: string, chatList: any) => {
    setSession(id);
    setSelected(0);
    setIsVisible(false);
    setFavorites([]);
   

    if (chatList && chatList.length > 0) {
      const newChats = chatList.flatMap((chat: any) => [
        {
          chat: chat.question,
          type: "user",
          starred: chat.starred,
        },
        {
          chat: chat.answer,
          type: "bot",
          references: chat.references,
          starred: chat.starred,
        },
      ]);

      setChats(newChats);
      setNewChat(false);
      setChatStarted(true);
    }
  };
  const renderSidebar = () => (
    <div
      className={`h-screen ${
        screenType.isMobile ? "w-[100%]" : "w-[20%]"
      } bg-[#0083BB] text-white flex flex-col`}
    >
      <div className="flex items-center justify-between px-4 my-4">
        <h1 className="text-xl font-bold">ChatMed</h1>
      </div>
      <div
        className="flex items-center justify-between p-1 my-2 px-2 mx-4 w-[80%] border-[1px] border-white rounded-[2px]"
        onClick={handleNewChat}
      >
        <h1 className="text-base ">Start new chat</h1>
        <button className="bg-white hover:bg-blue-200 text-xl text-blue-400 py-1 px-2">
          +
        </button>
      </div>
      <div
        className="flex items-center justify-between px-4 mt-2"
        onClick={handleFavourite}
      >
        <h1 className="text-lg cursor-pointer text-white transition-transform transform hover:scale-105 hover:text-white focus:outline-none focus:scale-105 active:scale-102 active:text-white">
          Favorite chats
        </h1>
      </div>
      <div className="flex-1 overflow-y-auto custom-scrollbar-2 pb-4 my-2">
        <h1 className="px-4 text-lg">Chat History</h1>

        {Object.entries(categorizedData).map(([day, chatList], index) => (
          <div
            key={index}
            className="pl-2 mt-4 border-l-2 ml-6 border-l-[#B8DCEC]"
          >
            <p className="text-white font-bold">{day}</p>
            <Menu theme="dark">
              {chatList?.reverse().map(({ id, chat }, idx) => {
                const firstQuestion =
                  chat.length > 0 ? chat[0].question.substring(0, 20) : "";
                return (
                  <Menu.Item
                    key={idx}
                    onClick={() => handleChatClick(id, chat)}
                  >
                    {firstQuestion && `${firstQuestion}...`}
                  </Menu.Item>
                );
              })}
            </Menu>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={`
      ${
        sidePanel ? "container":""
      }`} >
      <div
        className={`flex flex-row  ${
        sidePanel ? "mt-10 h-[100vh]":"mt-2 h-[80vh]"
      }`}
        
      >
        {sidePanel && (screenType.isMobile ? (
          <Drawer
            className="no-scrollbar"
            placement="left"
            closable={true}
            width={230}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            {renderSidebar()}
          </Drawer>
        ) : (
          renderSidebar()
        ))}
        <ChatContent
          sidePanel={sidePanel}
          screenType={screenType}
          newChat={newChat}
          loading={loading}
          isVisible={isVisible}
          isButtonVisible={isButtonVisible}
          chatStarted={chatStarted}
          chats={chats}
          rows={rows}
          question={question}
          chatInput={chatInput}
          chatLoading={chatLoading}
          allFavorites={allFavourites}
          textareaRef={textareaRef}
          selected={selected}
          messagesEndRef={messagesEndRef}
          favorites={favorites}
          // handleStart={handleStart}
          setDrawerVisible={setDrawerVisible}
          handleCategoryChange={handleCategoryChange}
          handleQuestionChange={handleQuestionChange}
          handleChatSubmit={handleChatSubmit}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          toggleVisibility={toggleVisibility}
          toggleFavorite={toggleFavorite}
          formatDateTime={formatDateTime}
       
        />
      </div>
    </div>
  );
};
