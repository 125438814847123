import { MenuFoldOutlined } from "@ant-design/icons";
import React from "react";

import NewChat from "./newChat";
import ChatStarted from "./chatStarted";
import Favorites from "./favorites";

interface ChatContentProps {
  sidePanel:any,
  screenType: any;
  question: any;
  newChat: boolean;
  isVisible: any;
  chatStarted: boolean;
  chats: any;
  chatInput: any;
  isButtonVisible: any;
  loading: boolean;
  selected: any;
  chatLoading: boolean;
  rows: any;
  allFavorites: any;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  favorites:any;
  // handleStart: () => void;
  handleCategoryChange: (value: string) => void;
  handleQuestionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChatSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  toggleVisibility: (index: number) => void;
  toggleFavorite: (index: number, chat: any) => void;
  formatDateTime: (dateString: string) => string;
  setDrawerVisible: (value: boolean) => void;
}

const ChatContent: React.FC<ChatContentProps> = ({
  sidePanel,
  screenType,
  rows,
  selected,
  chatInput,
  question,
  isButtonVisible,
  isVisible,
  newChat,
  loading,
  chatStarted,
  chats,
  chatLoading,
  allFavorites,
  textareaRef,
  messagesEndRef,
  favorites,
  // handleStart,
  handleCategoryChange,
  handleQuestionChange,
  handleChatSubmit,
  handleChange,
  handleKeyPress,
  toggleVisibility,
  toggleFavorite,
  formatDateTime,
  setDrawerVisible,
}) => {
  return (
    <div
      className={`bg-white
        ${
          !sidePanel ? "w-full h-[80vh]":""
        } 
        ${
        screenType.isMobile ? "w-full h-[100vh]" : "w-[80%] h-[100vh]"
      } flex flex-col border-r-[0.5px] [#BBB] border-b-[0.5px] [#BBB] border-l-[0.5px] [#BBB] mx-auto`}
    >
      <div className="relative w-full flex flex-col overflow-y-hidden h-full min-h-[60%]">
        <div className="h-[60px] border-t-[0.5px] [#BBB] border-b-[0.5px] flex gap-2 items-center [#BBB] bg-[#EEEEEE3D] py-2 px-3">
          {screenType.isMobile && sidePanel && (
            <div>
              <MenuFoldOutlined
                className="rotate-180"
                onClick={() => {
                  setDrawerVisible(true);
                }}
              />
            </div>
          )}
          <h5 className="strong">
            {newChat || chatStarted ? "ChatMed" : "Favorite chats"}
          </h5>
        </div>
        {/* {newChat ? (
          <NewChat
            question={question}
            isButtonVisible={isButtonVisible}
            loading={loading}
            handleCategoryChange={handleCategoryChange}
            handleQuestionChange={handleQuestionChange}
            handleStart={handleStart}
          
          />
        ) : */}
         {chatStarted ? (
          <ChatStarted
            sidePanel={sidePanel}
            chats={chats}
            chatLoading={chatLoading}
            messagesEndRef={messagesEndRef}
            rows={rows}
            chatInput={chatInput}
            textareaRef={textareaRef}
            selected={selected}
            isVisible={isVisible}
            favorites={favorites}
            toggleVisibility={toggleVisibility}
            toggleFavorite={toggleFavorite}
            handleKeyPress={handleKeyPress}
            handleChatSubmit={handleChatSubmit}
            handleChange={handleChange}
          
          />
        ) : (
          <Favorites
            allFavorites={allFavorites}
            isVisible={isVisible}
            selected={selected}
            formatDateTime={formatDateTime}
            toggleVisibility={toggleVisibility}
          
          />
        )}
      </div>
    </div>
  );
};

export default ChatContent;
