import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_HEADERS = {
  headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
};

export const getAllChats = () => axios.get(`${API_BASE_URL}/get_all_chats`, AUTH_HEADERS);

export const getAllFavChats = () => axios.get(`${API_BASE_URL}/get_all_fav_chats/`, AUTH_HEADERS);

export const makeDatabase = (question: string, category: string) =>
  axios.post(`${API_BASE_URL}/make_database`, { question, category }, AUTH_HEADERS);

export const sendMessageToBot = (session_id: string, question: string) =>

  {
    const is_new = !session_id;
    return axios.post(`${API_BASE_URL}/qabot`, { session_id, question, is_new}, AUTH_HEADERS);
  }


export const deleteFavoriteChat = (session_id: string, category: string, question: string) =>
  axios.post(`${API_BASE_URL}/delete_fav_chat`, { session_id, category, question }, AUTH_HEADERS);

export const makeChatFavorite = (session_id: string, category: string, question: string) =>
  axios.post(`${API_BASE_URL}/make_chat_fav`, { session_id, category, question }, AUTH_HEADERS);
