import React from "react";
import {
  DownOutlined,
  FileOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import { Button } from "antd";

interface ChatStartedProps {
  sidePanel:any,
  chats: any[];
  textareaRef: any;
  messagesEndRef: any;
  rows: any;
  chatInput: any;
  isVisible: boolean;
  selected: number;
  chatLoading: boolean;
  favorites:any;
  handleChange: (e: any) => void;
  handleChatSubmit: (e: any) => void;
  handleKeyPress: (e: any) => void;
  toggleVisibility: (index: number) => void;
  toggleFavorite: (index: number, chat: any) => void;
}

const ChatStarted: React.FC<ChatStartedProps> = ({
  sidePanel,
  chats,
  chatLoading,
  textareaRef,
  rows,
  chatInput,
  messagesEndRef,
  isVisible,
  selected,
  favorites,
  handleChange,
  handleChatSubmit,
  handleKeyPress,
  toggleVisibility,
  toggleFavorite,
}) => {
  return (
    <div className=" bg-white  w-full pt-6 pb-[45px] px-4 overflow-y-scroll h-[80%] custom-scrollbar-2">
      {chats?.map((chat, index) => (
        <div
          key={index}
          className="flex gap-2 items-start mb-2"
          style={{
            justifyContent: chat.type == "bot" ? "start" : "end",
            flexDirection: chat.type == "bot" ? "row" : "row-reverse",
          }}
        >
          <img
            src={
              chat.type == "bot"
                ? "/assets/icons/bot.svg"
                : "/assets/icons/user.svg"
            }
            height={30}
            width={30}
            alt="avatar"
          />
          <div className="max-w-[500px] relative mb-2">
            <div
              className={`p-[20px] ${
                chat.type == "bot"
                  ? "bg-[#F3F3F3] rounded-r-[10px] rounded-tl-lg"
                  : "bg-[#0083BB] rounded-l-[10px] rounded-tr-lg"
              }`}
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
              }}
            >
              <pre
                className={`whitespace-pre-line overflow-auto   ${
                  chat.type == "bot" ? "!text-black " : "!text-white "
                }`}
              >
                {chat.chat}
              </pre>
              {chat.type === "bot" && chat?.references && (
                
                <div className="mt-2">
                  <div
                    className="flex justify-between mx-auto cursor-pointer"
                    onClick={(e) => toggleVisibility(index)}
                  >
                    <div className="font-bold">References</div>
                    <div
                      className={
                        isVisible && selected === index ? "rotate-180" : ""
                      }
                    >
                      <DownOutlined />
                    </div>
                  </div>

                  {isVisible && selected === index && (
                      <ul className="list-decimal pl-4 overflow-auto">
                      {chat?.references.map((ref:any, i:any) => (
                         <div className="text-sm mt-2">
                         <p className="text-sm m-1 flex gap-1 break-words whitespace-normal"><span>{i+1}</span><span>{ref}</span></p>
                         {/* <a href={ref.source.pdf_url}>
                           <Button
                             type="primary"
                             icon={<FileOutlined />}
                             className="text-sm mt-2"
                           >
                             Download file
                           </Button>
                         </a> */}
                       </div>
                        // <li key={i} className="mt-2">
                        //   <a
                        //     href={ref.source.url}
                        //     target="_blank"
                        //     className="text-blue-500 underline"
                        //   >
                        //     {ref.source.title}
                        //   </a>
                        //   <div className="text-sm mt-2">
                        //     <p className="text-sm m-1">{ref.source.authors}</p>
                        //     <a href={ref.source.pdf_url}>
                        //       <Button type="primary" icon={<FileOutlined />} className="text-sm mt-2">
                        //         Download file
                        //       </Button>
                        //     </a>
                        //   </div>
                        // </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => toggleFavorite(index, chat)}
            >
              {chat.starred  ? (
                <HeartFilled style={{ color: "red" }} />
              ) : (
                <HeartOutlined />
              )}
            </div>
          </div>
        </div>
      ))}

      {chatLoading && (
        <div className="flex gap-2 items-start mb-2">
          <img
            src="/assets/icons/bot.svg"
            height={30}
            width={30}
            alt="avatar"
          />
          <div className="max-w-[500px] ">
            <div
              className="p-[10px] bg-[#F3F3F3] rounded-r-[10px] rounded-tl-lg"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
              }}
            >
              <span className="ellipsis-anim flex gap-1">
                <span>
                  <img
                    src="/assets/icons/dot.svg"
                    width={6}
                    height={6}
                    alt="e"
                  />
                </span>
                <span>
                  <img
                    src="/assets/icons/dot.svg"
                    width={6}
                    height={6}
                    alt="e"
                  />
                </span>
                <span>
                  <img
                    src="/assets/icons/dot.svg"
                    width={6}
                    height={6}
                    alt="e"
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}

      <div style={{ float: "left", clear: "both" }} ref={messagesEndRef}></div>
      <form
  onSubmit={handleChatSubmit}
  className={`absolute top-[84%] left-10 flex justify-between items-end px-4 bg-[#F1F2F6] border-[0.5px] rounded-[5.609px] border-solid border-[#A59F9F] ${
    sidePanel ? "w-[90%]" : "w-[80%]"
  }`}
>
  <div
    className={`min-h-[50px] relative flex items-end gap-[24px] ${
      sidePanel ? "w-[80%]" : "w-full"
    }`}
  >
    <textarea
      className="resizable-textarea custom-scrollbar-2 w-full outline-none resize-none h-[20px] max-h-[70px] pb-2 bg-[#F1F2F6] my-auto"
      rows={rows}
      value={chatInput}
      onChange={handleChange}
      placeholder="Enter your message..."
      ref={textareaRef}
      onKeyDown={handleKeyPress}
    />
  </div>
  <div className="min-h-[50px] flex items-end pb-3.5 gap-[30px]">
    {chatInput && (
      <button type="submit">
        <img
          src="/assets/icons/sendIcon.svg"
          height={30}
          width={30}
          alt="send"
        />
      </button>
    )}
  </div>
</form>

    </div>
  );
};

export default ChatStarted;
