import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Select, Radio, notification, Form, Spin, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const { Option } = Select;

const countries = [
  "United States",
  "Canada",
  "Pakistan",
  "India",
  "United Kingdom",
  "Australia",
  "Germany",
  "France",
  "China",
  "Japan",
];

const languages = ["English", "Spanish", "French", "German", "Mandarin", "Hindi"];

const specialties = [
  "Allergy And Immunology",
  "Anesthesiology",
  "Cardiology",
  "Dermatology",
  "Emergency",
  "Endocrinology",
  "Epidemiology",
  "Gastroenterology",
  "Genetics",
  "Geriatrics",
  "Gynecology",
  "Hospitalist",
  "Hematology",
  "Hepatology",
  "Infectious Disease",
  "Internal Medicine",
  "Neonatology",
  "Nephrology",
  "Neurology",
  "Neurosurgery",
  "Obstetrics Gynecology",
  "Oncology",
  "Ophthalmology",
  "Orthopedics",
  "Otolaryngology",
  "Pain Management",
  "Palliative Care",
  "Pediatrics",
  "Podiatry",
  "Preventive Medicine",
  "Psychiatry",
  "Pulmonology",
  "Radiology",
  "Rehabilitation Medicine",
  "Research",
  "Rheumatology",
  "Sleep Medicine",
  "Surgery",
  "Toxicology",
  "Transplantation",
  "Traumatology",
  "Urology",
  "Vascular Surgery",
  "Venereology",
];

const AdditionalInfoForm = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [profession, setProfession] = useState(null); // Add a state variable for profession
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = () => {
      getAccessTokenSilently()
        .then((accessToken) => {
          setAccessToken(accessToken);
          return axios.post(process.env.REACT_APP_BASE_URL + "/signup/", {
            token: accessToken,
          });
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
          
            message.success("Login successful!");
            localStorage.setItem("accessToken", response.data.token);
            navigate("/");
          }
        })
        .catch((error) => {
         
          if(error?.response?.status === 403 && (localStorage.getItem("action") === "signup")) {
           
            message.info("Signup successfull please verify your email and login.");
            setIsModalVisible(true);
            return
          }
          if (error?.response?.status === 403) {
          
            message.error("Verify your email and login.");
            navigate("/");
            return
          
          } 
        });
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    let userProfile;

    if (values.profession === "Physician") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        physician: true,
        speciality: values.speciality,
        npi: values.npi,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Medical Student") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        medical_student: true,
        school_name: values.schoolName,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Physician Assistant") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        physician_assistant: true,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    } else if (values.profession === "Other") {
      userProfile = {
        first_name: values.firstName,
        last_name: values.lastName,
        other_role: values.other_role,
        country: values.country,
        zip: values.zipCode,
        language: values.language,
        token: accessToken,
      };
    }

    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_BASE_URL + "/add-extra-info/", userProfile);
      console.log(response.data);
      message.success("Registration successful.");
      setIsModalVisible(false);
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      message.error("Registration failed.");
    }
  };

  return (
    <>
      {isModalVisible ? (
        <Modal
          width={460}
          title="User Registration - Additional Information"
          visible={isModalVisible}
          maskClosable={false}
          onCancel={() => {
            setIsModalVisible(false);
            navigate("/");
          }}
          footer={[
            <Button
              key="skip"
              onClick={() => {
                setIsModalVisible(false);
                navigate("/");
              }}
            >
              Skip for Now
            </Button>,
            <Button key="submit" type="primary" loading={loading} className="primary" onClick={() => form.submit()}>
              Continue
            </Button>,
          ]}
        >
          <Form
            form={form}
            requiredMark={false}
            onFinish={handleSubmit}
            onValuesChange={(changedValues, allValues) => {
              if ("profession" in changedValues) {
                setProfession(changedValues.profession); // Update profession on change
              }
            }}
            layout="vertical"
            className="w-full"
            initialValues={{
              country: countries[0],
              language: languages[0],
              speciality: specialties[0],
            }}
          >
            <div className="flex gap-8 items-center w-full">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "First name is required." }]}
              >
                <Input className="w-full" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Last name is required." }]}
              >
                <Input className="w-full" />
              </Form.Item>
            </div>
            <div className="flex gap-8 items-center w-full">
              <Form.Item
                label="Select Country"
                name="country"
                className="w-[45%]"
                rules={[{ required: true, message: "Country is required." }]}
              >
                <Select>
                  {countries.map((country) => (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Zip Code"
                name="zipCode"
                rules={[{ required: true, message: "Zip code is required." }]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              label="Select Language"
              name="language"
              rules={[{ required: true, message: "Language is required." }]}
            >
              <Select>
                {languages.map((language) => (
                  <Option key={language} value={language}>
                    {language}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Select Profession"
              name="profession"
              rules={[{ required: true, message: "Profession is required." }]}
            >
              <Radio.Group className="flex flex-col">
                {["Physician", "Medical Student", "Physician Assistant", "Other"].map((profession) => (
                  <Radio key={profession} value={profession}>
                    {profession}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {/* Conditional rendering based on profession */}
            {profession === "Physician" && (
              <>
                <Form.Item
                  label="Select Speciality"
                  name="speciality"
                  rules={[{ required: true, message: "Speciality is required." }]}
                >
                  <Select>
                    {specialties.map((speciality) => (
                      <Option key={speciality} value={speciality}>
                        {speciality}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Provide NPI"
                  name="npi"
                  rules={[
                    { required: true, message: "NPI is required." },
                    {
                      pattern: /^\d{10}$/,
                      message: "NPI must be exactly 10 digits.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}

            {profession === "Medical Student" && (
              <Form.Item
                label="School Name"
                name="schoolName"
                rules={[{ required: true, message: "School name is required." }]}
              >
                <Input />
              </Form.Item>
            )}

            {profession === "Other" && (
              <Form.Item
                label="Profession Name"
                name="other_role"
                rules={[{ required: true, message: "Profession name is required." }]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
        </Modal>
      ) : (
        <div className="flex justify-center h-screen items-center">
          <Spin size="large"/>
        </div>
      )}
    </>
  );
};

export default AdditionalInfoForm;
