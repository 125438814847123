import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Auth0Provider
    domain="dev-f3b6qaq16ugz2iat.us.auth0.com"
    clientId="FTHqBbatxB1huxDc4LC2zTs9o4olpfXc"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/auth`,
      audience: "https://token/user_info",
      scope: "openid profile email"
    }}
  >
 
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);


reportWebVitals();
