import React, { useEffect, useRef, useState } from "react";
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import {
  Button,
  Select,
  Collapse,
  Checkbox,
  Input,
  Spin,
  message,
  Form,
} from "antd";
import {
  LeftOutlined,
  AudioOutlined,
  EditOutlined,
  CopyOutlined,
  AudioMutedOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClinicalNotePatientForm from "../Dashboard/forms/ClinicalNotePatientForm";
import DifferentialDiagnosisPopup from "../DifferentialDiagnosis/Popup";
import ClinicalNotesModal from "./ClinicalNoteOutput/clinicalNotePopup";
import ClinicalNoteTemplate from "./clinicalNoteTemplate";
import useScreenType from "react-screentype-hook";
import { Bot } from "../../pages/aimedboxBot/bot";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function ClinicalNotes() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [clinicalOutput, setClinicalOutput] = useState({});
  const [openOutPut, setOpenOutPut] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  useEffect(() => {
    const storedData = sessionStorage.getItem("clinical-data");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setData(parsedData);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }, [sessionStorage.getItem("clinical-data")]);

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(liveTranscription);
      message.success("Transcription copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy transcription.");
    }
  };

  const [selectedValue, setSelectedValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [chiefFetching, setChiefFetching] = useState(false);
  const [diagnosisFetching, setDiagnosisFetching] = useState(false);
  const [differentialDiagnosisPopup, setDifferentialDiagnosisPopup] =
    useState(false);
  const [differentialDiagnosisPopupData, setDifferentialDiagnosisPopupData] =
    useState([]);
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  const handleDownload = () => {
    message.info("Downloading consent form template...");
  };

  const [patientDataFethcing, setPatientDataFetching] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState("");
  const canvasRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);

  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const isInitialRender = useRef(true);
  const [noteType, setNoteType] = useState("Soap");
  const [language, setLanguage] = useState("en");

  const screenType = useScreenType();

  const [chiefComplaintData, setChiefComplaintData] = useState([]);
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState([]);
  const [chief, setChief] = useState("");
  const [selectedSymptomsList, setSelectedSymptomsList] = useState([]);
  const [liveTranscription, setLiveTranscription] = useState("");
  const [open, setOpen] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  const connectionRef = useRef(null);

  const apiKeyRef = useRef("303d48dbe563fae74a7bc64c52b071fbf9d4af19");

  const startRecording = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        message.error("You are not authorized! Please login");
        return;
      }

      if (!apiKeyRef.current) {
        console.log("Deepgram API key is missing");
        return;
      }

      const deepgram = createClient(apiKeyRef.current);
      const connection = deepgram.listen.live({
        model: "nova-2-medical",
        language: "en-US",
        smart_format: true,
      });

      connectionRef.current = connection;

      connection.on(LiveTranscriptionEvents.Open, () => {
        setIsConnected(true);
        console.log("Deepgram connection opened");
      });

      connection.on(LiveTranscriptionEvents.Close, () => {
        setIsConnected(false);
        console.log("Deepgram connection closed");
      });

      connection.on(LiveTranscriptionEvents.Error, (err) => {
        console.error("Transcription error:", err);
      });

      connection.on(LiveTranscriptionEvents.Transcript, (data) => {
        const newTranscript = data.channel.alternatives[0].transcript;
        if (newTranscript) {
          setLiveTranscription((prev) => prev + "\n" + newTranscript);
          setTranscription((prevTranscription) => ({
            transcript: prevTranscription.transcript + "\n" + newTranscript,
          }));
        }
      });

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0 && connectionRef.current) {
          connectionRef.current.send(event.data);
        }
      };

      mediaRecorderRef.current.start(1000);

      audioContextRef.current = new AudioContext();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 2048;

      setIsRecording(true);
      drawWaveform();

      setLiveTranscription("");
      setTranscription({ transcript: "" });
    } catch (err) {
      console.error("Error in startRecording:", err);
      console.log("Failed to start recording");
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
    }

    if (connectionRef.current) {
      connectionRef.current.finish();
      connectionRef.current = null;
    }

    setIsRecording(false);
    cancelAnimationFrame(animationFrameRef.current);
  };

  const handleEditPatient = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (attempts >= 3) return;

    const timer = setTimeout(() => {
      if (transcription?.transcript && chiefComplaintData.length === 0) {
        fetchChiefComplaints()
          .then((data) => {
            if (data) {
              setAttempts(0);
            } else {
              setAttempts((prev) => prev + 1);
            }
          })
          .catch(() => {
            setAttempts((prev) => prev + 1);
          });
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [transcription?.transcript, attempts]);
  useEffect(() => {
    if (attempts >= 3) {
      fetchDiseases();
      fetchChiefDisease();
    }
  }, [attempts]);

  const fetchDiseases = async () => {
    setDiagnosisFetching(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/diseases/",
        {
          form_type: data[0]?.form_type,
          audio_transcript: transcription?.transcript,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setDifferentialDiagnosis(response.data);
      setSelectedValue(response.data.map((data) => data.diagnosis));
      setDiagnosisFetching(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return;
      }
      if (error?.response?.status == 403) {
        message.error("You are not authorized! please verified your email");
        return;
      }
      console.error("Error fetching differential diagnosis:", error);
      setDiagnosisFetching(false);
    }
  };
  const fetchChiefDisease = async () => {
    setChiefFetching(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/chief_disease/",
        {
          form_type: data[0]?.form_type,
          audio_transcript: transcription?.transcript,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setChief(response?.data?.chief_disease);
      setChiefFetching(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return;
      }
      if (error?.response?.status == 403) {
        message.error("You are not authorized! please verified your email");
        return;
      }
      console.error("Error fetching chief diagnosis:", error);
      setDiagnosisFetching(false);
    }
  };

  // Add debounced effect for differential diagnosis
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const timer = setTimeout(() => {
      if (selectedSymptomsList) {
        handleSubmitComplaints();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [selectedSymptomsList]);

  const DiagnosisPopup = (diagnosis) => {
    const diagnosisMatch = differentialDiagnosis.filter(
      (item) => item.diagnosis === diagnosis
    );
    console.log(diagnosisMatch);
    setDifferentialDiagnosisPopupData(diagnosisMatch);
    setDifferentialDiagnosisPopup(true);
  };

  const fetchChiefComplaints = async () => {
    setChiefFetching(true);
    if (transcription?.transcript) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/chief_complaint/",
          {
            audio_transcript: transcription?.transcript,
            form_type: data[0]?.form_type,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        const complaintsList = response?.data; // Assuming response.data is a list
        setChiefComplaintData(complaintsList);

        const initialSelections = {};

        complaintsList?.forEach((complaint) => {
          if (complaint.symptoms) {
            Object.keys(complaint.symptoms).forEach((key) => {
              initialSelections[key] = [];
            });
          }
          if (complaint.characterized_by) {
            Object.keys(complaint.characterized_by).forEach((key) => {
              initialSelections[key] = [];
            });
          }
          if (complaint.triggered_or_worsened_by) {
            Object.keys(complaint.triggered_or_worsened_by).forEach((key) => {
              initialSelections[key] = [];
            });
          }
          if (complaint.relieved_by) {
            Object.keys(complaint.relieved_by).forEach((key) => {
              initialSelections[key] = [];
            });
          }
          if (complaint.associated_with) {
            Object.keys(complaint.associated_with).forEach((key) => {
              initialSelections[key] = [];
            });
          }
        });

        // setSelectedComplaints(initialSelections);
        setChiefFetching(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          message.error("You are not authorized! Please login.");
          return;
        }
        if (error?.response?.status === 403) {
          message.error("You are not authorized! Please verify your email.");
          return;
        }
        console.error("Error fetching chief complaints:", error);
        setChiefFetching(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  const handleViewPdf = () => {
    const pdfUrl = process.env.REACT_APP_BASE_URL + data[0]?.file_url;
    window.open(pdfUrl, "_blank");
  };

  const handleComplaintChange = (category, values, chiefComplaint) => {
    setSelectedSymptomsList((prevState) => {
      const existingComplaint = prevState.find(
        (item) => item.chiefComplaint === chiefComplaint
      );

      if (existingComplaint) {
        const updatedList = prevState.map((item) =>
          item.chiefComplaint === chiefComplaint
            ? {
                ...item,
                symptoms: {
                  ...item.symptoms,
                  [category]: values,
                },
              }
            : item
        );
        return updatedList;
      } else {
        return [
          ...prevState,
          {
            chiefComplaint,
            symptoms: {
              [category]: values,
            },
          },
        ];
      }
    });
  };

  const handleSubmitComplaints = async () => {
    setDiagnosisFetching(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/differential_diagnosis/",
        {
          form_type: data[0]?.form_type,
          additional_symptoms: selectedSymptomsList,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }
      );
      setDifferentialDiagnosis(response.data);
      setSelectedValue(response.data.map((data) => data.diagnosis));
      setDiagnosisFetching(false);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return;
      }
      if (error?.response?.status == 403) {
        message.error("You are not authorized! please verified your email");
        return;
      }
      console.error("Error fetching differential diagnosis:", error);
      setDiagnosisFetching(false);
    }
  };

  const handleTemplateEdit = () => {
    setTemplateModal(true);
  };

  const handleSave = (formData) => {
    generateClinicalNotes(formData);
    setTemplateModal(false);
  };
  const generateClinicalNotes = async (template_json) => {
    setFetching(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/generate_clinical_note/",
        {
          audio_transcript: transcription.transcript,
          patient_name: data[0]?.patient_name,
          patient_dob: data[0]?.patient_dob,
          chief_complaint: chief ? chief : "",
          additional_symptoms: selectedSymptomsList ? selectedSymptomsList : [],
          form_json: data[0]?.form_json ? data[0]?.form_json : {},
          template_json: template_json,
          // differential_diagnosis: selectedValue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }
      );
      setFetching(false);
      setClinicalOutput(response?.data);
      setOpenOutPut(true);
    } catch (error) {
      if (error?.response?.status == 401) {
        message.error("You are not authorized! please login");
        return;
      }
      if (error?.response?.status == 403) {
        message.error("You are not authorized! please verified your email");
        return;
      }
      setFetching(false);
      console.error("Error fetching note:", error);
    }
  };

  const drawWaveform = () => {
    if (!canvasRef.current || !analyserRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return;

    const width = canvas.width;
    const height = canvas.height;
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    canvasCtx.clearRect(0, 0, width, height);

    const draw = () => {
      animationFrameRef.current = requestAnimationFrame(draw);
      analyserRef.current.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = "rgb(240, 240, 240)";
      canvasCtx.fillRect(0, 0, width, height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "rgb(0, 122, 255)";
      canvasCtx.beginPath();

      const sliceWidth = width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(width, height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  return (
    <div className="min-h-screen p-4">
      <div className="w-full  !shadow-lg rounded-lg overflow-hidden">
        <div className="p-4 border-b flex items-center justify-start">
          <Button
            onClick={(e) => navigate(-1)}
            icon={<LeftOutlined />}
            type="text"
          />
          <h1 className="text-xl font-semibold ml-1 mt-1">Clinical Notes</h1>
        </div>
        {patientDataFethcing ? (
          <div className="flex justify-center mt-3">
            <Spin indicator={<LoadingOutlined />} size="small" />
          </div>
        ) : (
          <div className="w-full space-y-4 p-4">
            {visible && (
              <div className="bg-green-700 text-white p-4 px-2  relative rounded-md lg:flex max-md:flex-col lg:justify-between max-md:justify-center items-center">
                <span>
                  Make sure your patient signed a consent form authorizing the
                  consultation to be recorded. In writing and signed by the
                  patient is valid for 1 year.
                </span>
                <div className="flex items-center max-md:justify-center max-md:mt-3">
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                    className="ml-3 mr-2 primary"
                  >
                    Download Consent
                  </Button>
                  <Button
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                    className="text-white absolute top-1 right-1"
                  />
                </div>
              </div>
            )}
            <div className="p-4 rounded-lg shadow-sm">
              <div className="flex lg:justify-between max-md:flex-col items-center">
                <div className="grid grid-cols-3 max-md:grid-cols-2 gap-4 flex-1">
                  <div>
                    <p className="text-sm text-black font-medium mb-1">
                      Patient Name
                    </p>
                    <p className="text-base text-gray-900">
                      {data[0]?.patient_name}
                    </p>
                  </div>

                  <div>
                    <p className="text-sm text-black mb-1 font-medium">
                      Patient Date of Birth
                    </p>
                    <p className="text-base text-gray-900">
                      {data[0]?.patient_dob}
                    </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  ghost
                  className="mt-2"
                  onClick={handleEditPatient}
                >
                  Edit Patient Information
                </Button>
              </div>
            </div>

            {data[0]?.form_name && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-blue-500 hover:text-blue-600 cursor-pointer text-base">
                      {data[0]?.form_name}
                    </p>
                  </div>
                  <Button
                    type="link"
                    icon={<FilePdfOutlined />}
                    onClick={handleViewPdf}
                    className="text-blue-500 hover:text-blue-600"
                  >
                    View Form
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex w-full max-md:flex-col">
          <div className="w-[60%] max-md:w-full p-4 lg:border-r">
            <div className="mb-4">
              <div className="h-12 bg-gray-200 bg-opacity-70 border rounded-lg flex items-center justify-between px-4">
                {isRecording ? (
                  <AudioOutlined className="text-blue-500" />
                ) : (
                  <AudioMutedOutlined className="text-blue-500" />
                )}
                <canvas ref={canvasRef} className="w-full h-full px-2" />
                <Button
                  type="primary"
                  onClick={isRecording ? stopRecording : startRecording}
                  className={
                    isRecording ? "bg-red-500 text-white" : "primary text-white"
                  }
                >
                  {isRecording ? "Stop Recording" : "Start Recording"}
                </Button>
              </div>
            </div>

            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">Transcription</h2>
              <TextArea
                rows={10}
                value={liveTranscription}
                onChange={(e) => {
                  setLiveTranscription(e.target.value);
                  setTranscription({ transcript: e.target.value });
                }}
                placeholder="Transcription will appear here..."
                disabled={!isEditable}
              />
            </div>

            <div className="flex justify-start gap-x-2 mb-4">
              <Button onClick={handleEdit} icon={<EditOutlined />} />
              <Button onClick={handleCopy} icon={<CopyOutlined />} />
            </div>

            {!screenType.isMobile && (
              <Button
                onClick={handleTemplateEdit}
                disabled={
                  (!chiefComplaintData || !chief) &&
                  !differentialDiagnosis &&
                  !transcription
                }
                loading={fetching}
                type="primary"
                className="primary text-white"
              >
                Generate Clinical Notes
              </Button>
            )}
          </div>

          <div className="w-[40%] max-md:w-full p-4">
            <div className="!flex !justify-start !items-center !gap-3 !mb-4 w-full">
              <Select
                className="w-1/2"
                placeholder="Select Note Type"
                onChange={(value) => setNoteType(value)}
                value={noteType}
              >
                <Option value="Soap">Soap</Option>
                <Option value="Progress">Progress</Option>
              </Select>

              <Select
                className="w-1/2"
                placeholder="Select Language"
                value={language}
                onChange={(value) => setLanguage(value)}
              >
                <Option value="en">English</Option>
                <Option value="es">Spanish</Option>
              </Select>
            </div>

            <Collapse>
              <Panel
                header={
                  <div className="flex items-center justify-between">
                    <span>Chief Complaint</span>
                    {chiefFetching && <Spin size="small" />}
                  </div>
                }
                key="1"
              >
                {chiefComplaintData.length > 0 ? (
                  <div className="space-y-4 mt-1">
                    {chiefComplaintData?.map((complaint, index) => (
                      <div key={index} className="mb-6">
                        <h3 className="font-semibold text-lg mb-2">
                          Chief Complaint: {complaint.chief_complaint}
                        </h3>

                        {Object.entries(complaint.symptoms || {}).map(
                          ([category, options]) => (
                            <div key={category} className="mb-4">
                              <p className="font-medium">{category}:</p>
                              <Checkbox.Group
                                options={options}
                                value={
                                  selectedSymptomsList.find(
                                    (item) =>
                                      item.chiefComplaint ===
                                      complaint.chief_complaint
                                  )?.symptoms[category] || []
                                }
                                onChange={(values) =>
                                  handleComplaintChange(
                                    category,
                                    values,
                                    complaint.chief_complaint
                                  )
                                }
                              />
                            </div>
                          )
                        )}

                        {Object.entries(complaint.characterized_by || {}).map(
                          ([category, options]) => (
                            <div key={category} className="mb-4">
                              <p className="font-medium">{category}:</p>
                              <Checkbox.Group
                                options={options}
                                value={
                                  selectedSymptomsList.find(
                                    (item) =>
                                      item.chiefComplaint ===
                                      complaint.chief_complaint
                                  )?.symptoms[category] || []
                                }
                                onChange={(values) =>
                                  handleComplaintChange(
                                    category,
                                    values,
                                    complaint.chief_complaint
                                  )
                                }
                              />
                            </div>
                          )
                        )}

                        {Object.entries(
                          complaint.triggered_or_worsened_by || {}
                        ).map(([category, options]) => (
                          <div key={category} className="mb-4">
                            <p className="font-medium">{category}:</p>
                            <Checkbox.Group
                              options={options}
                              value={
                                selectedSymptomsList.find(
                                  (item) =>
                                    item.chiefComplaint ===
                                    complaint.chief_complaint
                                )?.symptoms[category] || []
                              }
                              onChange={(values) =>
                                handleComplaintChange(
                                  category,
                                  values,
                                  complaint.chief_complaint
                                )
                              }
                            />
                          </div>
                        ))}

                        {Object.entries(complaint.relieved_by || {}).map(
                          ([category, options]) => (
                            <div key={category} className="mb-4">
                              <p className="font-medium">{category}:</p>
                              <Checkbox.Group
                                options={options}
                                value={
                                  selectedSymptomsList.find(
                                    (item) =>
                                      item.chiefComplaint ===
                                      complaint.chief_complaint
                                  )?.symptoms[category] || []
                                }
                                onChange={(values) =>
                                  handleComplaintChange(
                                    category,
                                    values,
                                    complaint.chief_complaint
                                  )
                                }
                              />
                            </div>
                          )
                        )}

                        {Object.entries(complaint.associated_with || {}).map(
                          ([category, options]) => (
                            <div key={category} className="mb-4">
                              <p className="font-medium">{category}:</p>
                              <Checkbox.Group
                                options={options}
                                value={
                                  selectedSymptomsList.find(
                                    (item) =>
                                      item.chiefComplaint ===
                                      complaint.chief_complaint
                                  )?.symptoms[category] || []
                                }
                                onChange={(values) =>
                                  handleComplaintChange(
                                    category,
                                    values,
                                    complaint.chief_complaint
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  attempts >= 3 && (
                    <Form
                      form={form}
                      layout="vertical"
                      className="mt-2"
                      fields={[
                        {
                          name: ["chiefDisease"],
                          value: chief,
                        },
                      ]}
                      requiredMark={false}
                    >
                      <Form.Item
                        name="chiefDisease"
                        rules={[
                          {
                            required: true,
                            message: "chief disease required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="chief disease"
                          value={chief}
                          onChange={(value) => setChief(value)}
                        />
                      </Form.Item>
                    </Form>
                  )
                )}
              </Panel>

              <Panel
                header={
                  <div className="flex items-center justify-between">
                    <span>Differential Diagnosis</span>
                    {diagnosisFetching && <Spin size="small" />}
                  </div>
                }
                key="2"
              >
                <div className="mt-4 bg-gray-100 mx-2 h-[300px] pt-4 pb-4 overflow-y-auto">
                  {selectedValue.length > 0 &&
                    selectedValue?.map((value, i) => (
                      <div key={i} className="px-4 mt-2 flex justify-between">
                        <p className="text-black font-base underline underline-offset-4 w-[70%]">
                          {value}
                        </p>

                        <p
                          onClick={() => DiagnosisPopup(value)}
                          className="text-blue-400 font-base underline underline-offset-4 cursor-pointer w-[30%]"
                        >
                          See factors
                        </p>
                      </div>
                    ))}
                </div>
              </Panel>
              <Panel header={<span>ChatMed</span>} key="3">
                <div>
                  <Bot sidePanel={false} />
                </div>
              </Panel>
            </Collapse>

            {screenType.isMobile && (
              <div className="w-full mt-4 flex justify-center items-center">
                <Button
                  onClick={handleTemplateEdit}
                  disabled={
                    !chiefComplaintData &&
                    !differentialDiagnosis &&
                    !transcription
                  }
                  loading={fetching}
                  type="primary"
                  className="primary text-white"
                >
                  Generate Clinical Notes
                </Button>
              </div>
            )}
          </div>
          <ClinicalNotePatientForm
            isOpen={open}
            setOpenPatientClinicalForm={setOpen}
            edit={data[0]}
          />
          <DifferentialDiagnosisPopup
            isOpen={differentialDiagnosisPopup}
            onClose={setDifferentialDiagnosisPopup}
            tabContent={differentialDiagnosisPopupData[0]}
          />
          {clinicalOutput && (
            <ClinicalNotesModal
              data={clinicalOutput}
              isOpen={openOutPut}
              onClose={setOpenOutPut}
            />
          )}

          <ClinicalNoteTemplate
            isVisible={templateModal}
            onClose={() => setTemplateModal(false)}
            onSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
