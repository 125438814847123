import { Modal, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const DifferentialDiagnosisPopup = ({ isOpen, onClose, activeTab = '1', tabContent }) => {

  const items = [
    {
      key: '1',
      label: 'Associated Factors',
      children: (
        <div
        className="text-gray-700 overflow-y-auto w-full h-[450px]"
        dangerouslySetInnerHTML={{
          __html: tabContent?.associated_factors?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
    {
      key: '2',
      label: 'Treatment ',
      children: (
        <div
        className="text-gray-700 overflow-y-auto w-full h-[450px]"
        dangerouslySetInnerHTML={{
          __html: tabContent?.TREATMENT?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
    {
      key: '3',
      label: 'Diagnosis',
      children: (
        <div
        className="text-gray-700 overflow-y-auto w-full h-[450px]"
        dangerouslySetInnerHTML={{
          __html: tabContent?.DIAGNOSIS?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
    {
      key: '4',
      label: 'Evaluation',
      children:(
        <div
        className="text-gray-700 overflow-y-auto w-full h-[450px]"
        dangerouslySetInnerHTML={{
          __html: tabContent?.EVALUATION?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
    {
      key: '5',
      label: 'References',
      children: (
      <div
        className="text-gray-700 overflow-y-auto w-full h-[450px]"
        dangerouslySetInnerHTML={{
          __html: tabContent?.references?.replace(/\n/g, '<br />')
        }}
      />
      ),
    },
  ];

  return (
   
    <Modal
      open={isOpen}
      onCancel={()=>onClose(!isOpen)}
      footer={null}
      width={600}
    
      maskClosable={false}
      closeIcon={<CloseOutlined className="text-white" />}
      className="w-full"
    >
      <div className="bg-[#0096D6] text-white p-4 -mt-4 -mx-6 mb-4">
        <h2 className="text-xl font-semibold mb-2 text-center">{tabContent?.diagnosis}</h2>
       
      </div>
      
      <Tabs
        defaultActiveKey={activeTab}
        items={items}
       
      />
    </Modal>
 
  );
};

export default DifferentialDiagnosisPopup;
