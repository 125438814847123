import { DownOutlined, FileOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface FavoritesProps {
  formatDateTime:any
  allFavorites: any[];
  isVisible: boolean;
  selected: number;
  toggleVisibility: (index: number) => void;
  
}

const Favorites: React.FC<FavoritesProps> = ({
  allFavorites,
  formatDateTime,
  isVisible,
  selected,
  toggleVisibility,
 
}) => {
  return (
    (
              <div className="m-2 px-2 overflow-y-scroll">
              {allFavorites?.map((item:any, index) => (
                <div
                  key={index}
                  className="p-[10px] mt-2 bg-[#F3F3F3] rounded"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}
                >
                  <div className="flex justify-between cursor-pointer" onClick={() => toggleVisibility(index)}>
                    <div className="font-bold">
                      <h1 className="text-lg">{item.chats.question}
                       {/* <span className="text-gray-500">({item.chats.category})</span> */}
                       </h1> 
                    </div>
                    <div className="flex items-center">
                      <div className="text-gray-500 mr-2">{formatDateTime(item.chats.created_at)}</div>
                      <div className={isVisible && selected === index ? "rotate-180" : ""}>
                        <DownOutlined />
                      </div>
                    </div>
                  </div>
        
                  {isVisible && selected === index && (
                    <>
                      <pre className="whitespace-pre-line overflow-auto text-black mt-2">
                        {item.chats.answer}
                      </pre>
                      <div className="mt-2 w-full">
                        <div className="font-bold">References</div>
                        <ul className="list-decimal pl-4 overflow-auto">
                          {item.chats.references.map((ref:any, i:any) => (
                             <div className="text-sm mt-2">
                             <p className="text-sm m-1 flex gap-1 break-words whitespace-normal"><span>{i+1}</span><span>{ref}</span></p>
                             {/* <a href={ref.source.pdf_url}>
                               <Button
                                 type="primary"
                                 icon={<FileOutlined />}
                                 className="text-sm mt-2"
                               >
                                 Download file
                               </Button>
                             </a> */}
                           </div>
                            // <li key={i} className="mt-2">
                            //   <a
                            //     href={ref.source.url}
                            //     target="_blank"
                            //     className="text-blue-500 underline"
                            //   >
                            //     {ref.source.title}
                            //   </a>
                            //   <div className="text-sm mt-2">
                            //     <p className="text-sm m-1">{ref.source.authors}</p>
                            //     <a href={ref.source.pdf_url}>
                            //       <Button type="primary" icon={<FileOutlined />} className="text-sm mt-2">
                            //         Download file
                            //       </Button>
                            //     </a>
                            //   </div>
                            // </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
        )
  );
};

export default Favorites;
