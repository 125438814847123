

import React, { useEffect, useState } from 'react'
import { Modal, Input, Radio, Select, Button ,Form, DatePicker, message} from 'antd'
import dayjs from 'dayjs';

import axios from 'axios';
import { formNames } from '../../../formNames';

export default function PatientIntakeForm(props) {
  const [form] = Form.useForm();

  const [options, setOptions] = useState([]);
  const [fetching,setFetching]=useState(false);

  useEffect(() => {
    const options = formNames.map((f) => ({
        value: `https://forms.aimedbox.com/form/${f}`,
        label: f,
      }));
      setOptions(options)
  }, []);

  

  

  
 
  const [formType, setFormType] = useState('adult')

  const handleSubmit = () => {
    setFetching(true)
    form
      .validateFields()
      .then(async (values) => {
      
        try {
          console.log("Form values:", values); 
         
          const response = await axios.post(process.env.REACT_APP_BASE_URL+'/send_form/', {
            patient_name:values.patientName,
            patient_email:values.patientEmail,
            patient_dob:values.dateOfBirth.format('YYYY-MM-DD'),
            form_link:values.relevantForm,
            form_type:values.formType

          },
          
          {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Set the token from local storage as a Bearer token in the Authorization header
          },
        }
        );
          console.log("Server response:", response.data);
          form.resetFields();
          setFetching(false)
          message.success("patient intake form sent successfully!")
          props.setOpenPatientIntakeForm();
        } catch (error) {
          if (error?.response?.status == 401) {
            message.error("You are not authorized! please login");
            return
          }
          if (error?.response?.status == 403) {
            message.error("You are not authorized! please verified your email");
            return
          }
          console.error("Error submitting form:", error);
          setFetching(false)
        
        }
        
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setFetching(false)
      });
  };

  const handleClose = () => {
    form.resetFields();
    props.setOpenPatientIntakeForm();
    setFetching(false) 
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf('day');
  };

 


  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
    <Modal
      title="New Patient Intake Form"
      open={props.openPatientIntakeForm}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
       className='w-full'
    >
      <Form
        form={form}
        layout="vertical"
        className="space-y-4"
        initialValues={{ formType: "adult" }}
        requiredMark={false}
      >
        <Form.Item
          label="Name of the Patient"
          name="patientName"
          rules={[
            { required: true, message: 'Please enter patient name' },
            { min: 2, message: 'Name must be at least 2 characters' },
            { max: 50, message: 'Name cannot exceed 50 characters' },
            {
              pattern: /^[a-zA-Z\s]*$/,
              message: 'Name can only contain letters and spaces'
            }
          ]}
        >
          <Input 
            placeholder="Eg: James Franklin"
            className="w-full p-2"
          />
        </Form.Item>

        <Form.Item
          label="Email of the Patient"
          name="patientEmail"
          rules={[
            { required: true, message: "Please enter the patient's email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input className="p-2" placeholder="Eg: abc@gmail.com" />
        </Form.Item>
        <Form.Item
          label="Patient Date of Birth"
          name="dateOfBirth"
          rules={[
            { required: true, message: 'Please select date of birth' },
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                
                const year = value.year();
                if (year < 1900) {
                  return Promise.reject('Invalid year');
                }
                
                return Promise.resolve();
              }
            }
          ]}
        >
          <DatePicker
            className="w-full p-2"
            format="DD-MM-YYYY"
            disabledDate={disableFutureDates}
            placeholder="Select date of birth"
            showToday={false}
            popupClassName="custom-datepicker"
            showTime={false}
          />
        </Form.Item>

        <Form.Item label="Select Patient Form" name="formType"  rules={[
            { required: true, message: 'Please select patient form type' }
          ]}>
          <Radio.Group onChange={(e) => setFormType(e.target.value)} value={formType}>
            <Radio value="adult">Adult</Radio>
            <Radio value="pediatric">Pediatric</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
  label="Select Relevant Form"
  name="relevantForm"
  rules={[
    { required: true, message: 'Please select a form' },
  ]}
>
          <Select
            placeholder="Select Relevant Form"
            className="w-full"
            options={options.length ? options : [{ label: 'No forms available', value: null, disabled: true }]}
            allowClear

          />
        </Form.Item>

        <div className="flex justify-end">
          <Button type="primary" loading={fetching} className="primary text-white" onClick={handleSubmit}>
            Send Intake Form
          </Button>
        </div>
      </Form>
    </Modal>
  </div>
    
  )
}