

// utils/validateInput.ts
const blacklist = /<script>|img|http|&|>|<|'|"|=|\/|link|!|CSS/gi;


export const validateInput = (input: string): boolean => {
  return blacklist.test(input);
};


